import React, { useEffect, useRef, useState } from "react";
//@ts-ignore
import $ from "jquery";
import { motion } from "framer-motion";
import "./intro.scss";

export default function Intro() {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    //@ts-ignore
    $(window).on("scroll", function () {
      if (window.scrollY > 185) {
        setIsVisible(false);
      }
    });
  }, []);
  useEffect(() => {
    var text = $(".name").text(),
      textArr = text.split("");

    $(".name").html("");

    //@ts-ignore
    $.each(textArr, function (i, v) {
      if (v === " ") {
        $(".name").append('<span class="space"></span>');
      }
      $(".name").append("<span>" + v + "</span>");
    });
  });
  return (
    <div className="intro">
      <section className="wrapper">
        <div className="circle__container">
          <div id="scene" className="scene" data-hover-only="false">
            <div className="circle" data-depth="1.2"></div>
            <div className="one" data-depth="0.9">
              <div className="content" />
            </div>
            <h2 className="title">
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{
                  type: "spring",
                  delay: 1.2,
                  stiffness: 100,
                }}
              >
                Hi, I'm
              </motion.div>
            </h2>
            <span className="name" data-depth="0.50">
              Mahdi
            </span>
            <div className="subtitle">
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{
                  type: "spring",
                  delay: 2,
                  stiffness: 100,
                }}
              >
                Student,
                {/* <span className="subtitle-text">
                  Front-End Mobile & Web Developer
                </span> */}
              </motion.div>
              <motion.div
                initial={{ x: -100, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{
                  delay: 2.5,
                }}
              >
                &nbsp;Experienced Full-stack Developer
              </motion.div>
            </div>
          </div>
        </div>
      </section>
      <div className={isVisible ? "scrollDown" : "scrollDown deactive"} />
    </div>
  );
}
