import React from "react";
import "./projects.scss";
import { RiToolsFill, RiGithubFill } from "react-icons/ri";
import { IoCodeSharp, IoLogoReact } from "react-icons/io5";
import { FaFigma } from "react-icons/fa";
import { SiRedux } from "react-icons/si";
import { TiWeatherCloudy } from "react-icons/ti";
import { motion } from "framer-motion";
import Ushop from "../../images/Ushop.png";
import CJSF from "../../images/CJSF-clone.png";
import Dweather from "../../images/DWeather.png";
import Forest from "../../images/Forest.png";
const Projects: React.FC = () => {
  return (
    <div className="projects container" id="projects">
      <motion.h1
        initial="hidden"
        whileInView="visible"
        viewport={{
          once: true,
        }}
        transition={{
          y: { type: "spring", damping: 15 },
          default: { duration: 1 },
        }}
        variants={{
          visible: { y: 0, opacity: 1 },
          hidden: { y: 100, opacity: 0 },
        }}
        className="projects-title"
      >
        Projects
      </motion.h1>
      <ul id="cards">
        <motion.li
          initial="hidden"
          whileInView="visible"
          viewport={{
            once: true,
          }}
          variants={{
            hidden: { scale: 0 },
            visible: { scale: 1 },
          }}
          transition={{
            duration: 0.2,
            ease: "easeInOut",

            scale: { type: "spring", damping: 15 },
            default: { duration: 1 },
          }}
          className="card"
          id="card_1"
        >
          <div className="card__content">
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{
                once: true,
              }}
              transition={{ duration: 0.8 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 },
              }}
            >
              <div>
                <h2 className="card__title">CJSF-App-Clone</h2>
                <div className="card__description">
                  <p className="card__description-text">
                    <IoCodeSharp size={25} />
                    <span className="card__description-text__span">
                      JavaScript
                    </span>
                  </p>
                  <p className="card__description-text">
                    <RiToolsFill size={25} />
                    <span className="card__description-text__span">
                      ReactNative, Redux, Figma
                    </span>
                  </p>
                  <div className="card-links">
                    <div className="tools-container">
                      <IoLogoReact className="react-icon" />
                      <FaFigma className="figma-icon" />
                      <SiRedux className="redux-icon" />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "1.2rem",
                          color: "white",
                          // fontWeight: "semi-bold",
                          marginRight: "15px",
                          // marginTop: "0px",
                        }}
                      >
                        Source:
                      </p>
                      <a
                        href="https://github.com/Mhd-tz/cjsf_radio_app_clone"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="git-link"
                      >
                        <RiGithubFill
                          size={25}
                          style={{
                            marginTop: "5px",
                          }}
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
            <figure>
              <div className="card__image__background">
                <motion.img
                  src={CJSF}
                  className="card__image"
                  initial="hidden"
                  whileInView="visible"
                  viewport={{
                    once: true,
                  }}
                  transition={{ duration: 1 }}
                  variants={{
                    visible: { opacity: 1, scale: 1 },
                    hidden: { opacity: 0, scale: 0 },
                  }}
                  alt="CJSF-App-Clone"
                />
              </div>
            </figure>
          </div>
        </motion.li>
        <li className="card" id="card_2">
          <div className="card__content">
            <div>
              <div>
                <h2 className="card__title">DWeather-Web</h2>
                <div className="card__description">
                  <p className="card__description-text">
                    <IoCodeSharp size={25} />
                    <span className="card__description-text__span">
                      JavaScript
                    </span>
                  </p>
                  <p className="card__description-text">
                    <RiToolsFill size={25} />
                    <span className="card__description-text__span">
                      React, AccuWeather, Figma
                    </span>
                  </p>
                  <div className="card-links">
                    <div className="tools-container">
                      <IoLogoReact className="react-icon" />
                      <FaFigma className="figma-icon" />
                      <TiWeatherCloudy className="weather-icon" />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "1.2rem",
                          color: "white",
                          // fontWeight: "semi-bold",
                          marginRight: "15px",
                          // marginTop: "0px",
                        }}
                      >
                        Source:
                      </p>
                      <a
                        href="https://github.com/Mhd-tz/dweather"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="git-link"
                      >
                        <RiGithubFill
                          size={25}
                          style={{
                            marginTop: "5px",
                          }}
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <figure>
              <div className="card__image__background">
                <img
                  alt="DWeather-Web-App"
                  src={Dweather}
                  className="card__image num2"
                />
              </div>
            </figure>
          </div>
        </li>
        <li className="card" id="card_3">
          <div className="card__content">
            <div>
              <div>
                <h2 className="card__title">UShop-App</h2>
                <div className="card__description">
                  <p className="card__description-text">
                    <IoCodeSharp size={25} />
                    <span className="card__description-text__span">
                      TypeScript
                    </span>
                  </p>
                  <p className="card__description-text">
                    <RiToolsFill size={25} />
                    <span className="card__description-text__span">
                      ReactNative, Redux, Figma
                    </span>
                  </p>

                  <div className="card-links">
                    <div className="tools-container">
                      <IoLogoReact className="react-icon" />
                      <FaFigma className="figma-icon" />
                      <SiRedux className="redux-icon" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <figure>
              <div className="card__image__background">
                <img alt="UShop-App" src={Ushop} className="card__image num3" />
              </div>
            </figure>
          </div>
        </li>
        <li className="card" id="card_4">
          <div className="card__content">
            <div>
              <div>
                <h2 className="card__title">AI-Driven Game</h2>
                <div className="card__description">
                  <p className="card__description-text">
                    <IoCodeSharp size={25} />
                    <span className="card__description-text__span">
                      Java, Python
                    </span>
                  </p>
                  <div className="card-links">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "1.2rem",
                          color: "white",
                          // fontWeight: "semi-bold",
                          marginRight: "15px",
                          // marginTop: "0px",
                        }}
                      >
                        Source:
                      </p>
                      <a
                        href="https://github.com/Mhd-tz/Simulation-World"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="git-link"
                      >
                        <RiGithubFill
                          size={25}
                          style={{
                            marginTop: "5px",
                          }}
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <figure>
              <div className="card__image__background">
                <img
                  alt="Forest-AI-Game"
                  src={Forest}
                  className="card__image num4"
                />
              </div>
            </figure>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default Projects;
