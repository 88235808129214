import React from "react";
import { IoArrowForward } from "react-icons/io5";
import "./expandingButton.scss";

interface ExpandingButtonProps {
  onClick: () => void;
  label?: string;
  color?: string;
}

const ExpandingButton: React.FC<ExpandingButtonProps> = ({
  onClick,
  label = "Visit",
  color = "#0077cc",
}) => {
  return (
    <button className="expanding-button" onClick={onClick} style={{ color }}>
      <span className="text">{label}</span>

      <span className="icon">
        <IoArrowForward />
      </span>
    </button>
  );
};

export default ExpandingButton;
