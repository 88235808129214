import React, { useState } from "react";
import "./exp.scss";
import Guessfit from "../../images/GuessfitLogo.jpg";
import CJSF from "../../images/CJSFLogo.jpg";
import CJSFVideo from "../../videos/CJSF-work.gif";
import GuessfitImage from "../../images/Guessfit.png";
import Inobat2 from "../../images/Inobat2.png";
import InobatWeb from "../../images/image1.jpeg";
import { motion } from "framer-motion";
import ExpCard from "../ExpCard/ExpCard";
import Zenfeatlogo from "../../images/zenfeat.png";
import ZenfeatShow from "../../images/zenfeat-des.png";
import ZenfeatPre from "../../images/zenfeat-pre.png";

export default function Exp() {
  const [expandedDay, setCollapsedDay] = useState();
  const EXP_DATA = [
    {
      title: "Zenfeat",
      tools: "ReactNative Development",
      description:
        "Zenfeat is an innovative mobile application that reimagines local food ordering by creating a unique marketplace that connects home chefs with food enthusiasts in their community. Unlike traditional food delivery platforms that rely solely on restaurants, Zenfeat celebrates local culinary talent by providing a platform for skilled home cooks to showcase and monetize their cooking skills.",
      image: Zenfeatlogo,
      proImage: ZenfeatShow,
      color: "#F09840",
      site: "https://zenfeat-app.webflow.io/",
      alt: "Zenfeat Logo",
      cover: false,
      date: "2023-2024",
      tasks: [
        "React Native",
        "Webflow",
        "Supabase",
        "UI/UX design",
        "Sales funnels",
      ],
      preprationTitle: "More Chefs, More Choices",
      preprationDescription:
        "As a full-stack developer, I was responsible for developing the mobile application using React Native, I also worked on the UI/UX design and the sales funnels for the application to increase the user engagement and the conversion rate. One of the main challenges was to create a seamless user experience for the home chefs and the food enthusiasts.",
      Colors: ["#F0983F", "#341853", "#F6F5F3", "#000000"],
      preImage: ZenfeatPre,

      Fonts: [
        {
          title: "Roboto",
          samples: [
            "a b c d e f g h i j k l",
            "m n o p q r s t u v w x",
            "y z 1 2 3 4 5 6 7 8 9 0",
          ],
        },
        {
          title: "Poppins",
          samples: [
            "a b c d e f g h i j k l",
            "m n o p q r s t u v w x",
            "y z 1 2 3 4 5 6 7 8 9 0",
          ],
        },
      ],

      designDescription: [
        "Inspired by modern, minimalistic design trends",
        "Warm color palette for inviting atmosphere",
        "Clean, readable fonts for easy navigation",
        "User-friendly and intuitive interface design",
      ],
    },
    {
      title: "inobat",
      tools: "Next.js Development",
      description:
        "Inoobat is a web application that allows the clinics and hospitals to manage their patients and their appointments. Worked on both the front-end and the back-end of the application, first time experince with server-side rendering and Next.js.",
      image: Inobat2,
      proImage: InobatWeb,
      color: "#3ab372",
      site: "https://inobat.vercel.app",
      alt: "Inoobat Logo",
      cover: true,
      date: "2021-2022",
      tasks: [
        "Next.js",
        "UI/UX design",
        "Back4app",
        "realtime database",
        "35%↑ conversion rate",
      ],
      preprationTitle: "Easier Appointments, Better Care",
      preprationDescription:
        "As a full-stack developer, I was responsible for developing the web application using Next.js, I also worked on the UI/UX design for the application to increase the user engagement and the conversion rate. One of the main challenges was to create a seamless user experience for the clinics and the patients.",
      Colors: ["#3AB372", "#F6F5F3", "#000000", "#1C2536"],
      AlternativeColors: ["#3AB372", "#F6F5F3", "#000000", "#1C2536"],
      Fonts: [
        {
          title: "Vazir",
          samples: [
            "a b c d e f g h i j k l",
            "m n o p q r s t u v w x",
            "y z 1 2 3 4 5 6 7 8 9 0",
          ],
        },
      ],
      designDescription: [
        "Inspired by modern, minimalistic design trends",
        "relaxing color palette for a calming atmosphere",
        "Readable fonts for easy navigation",
        "Easy-to-use interface design",
      ],
    },
    {
      title: "Guessfit",
      tools: "ReactNative Development",
      description:
        "Guessfit is a training mobile application for athletes. With its AI-driven coaching and meal planning, you can take the guesswork out of your workout routine and focus on reaching your fitness goals.",
      image: Guessfit,
      proImage: GuessfitImage,
      color: "#2B5098",
      isImage: true,
      site: "https://guessfit.ca",
      alt: "Guessfit Logo",
      cover: false,
      date: "2020-2021",
      tasks: [
        "React Native",
        "UI/UX design",
        "Firebase",
        "AI-driven coaching",
        "Meal planning",
      ],
      preprationTitle: "Train Smarter, Not Harder",
      preprationDescription:
        "As a full-stack developer, I was responsible for developing the mobile application using React Native, I also worked on the UI/UX design for the application to increase the user engagement and the conversion rate. One of the main challenges was to create a seamless user experience for the athletes.",
      Colors: ["#2B5098", "#F6F5F3", "#000000"],
      // preImage: GuessfitImage,
      Fonts: [
        {
          title: "Roboto",
          samples: [
            "a b c d e f g h i j k l",
            "m n o p q r s t u v w x",
            "y z 1 2 3 4 5 6 7 8 9 0",
          ],
        },
      ],
      designDescription: [
        "Inspired by a fast-paced, energetic lifestyle",
        "Cool color palette for a refreshing atmosphere",
        "Readable fonts for easy navigation",
        "A dynamic and engaging interface design",
      ],
    },
    {
      title: "CJSF",
      tools: "ReactNative Development",
      description:
        "The CJSF 90.1 FM application allows the user and the listeners to listen to On Air programs on CJSF 90.1 FM. The project started as a side project and was later expanded to a full blown web application.",
      image: CJSF,
      video: CJSFVideo,
      color: "#27C7BC",
      site: "https://cjsf.ca",
      mobile: true,
      alt: "CJSF Logo",
      cover: false,
      date: "2019-2021",
      tasks: ["React Native", "UI/UX design", "Radio streaming", "Podcasts"],
      preprationTitle: "Listen to the World",
      preprationDescription:
        "As a front-end developer, I was responsible for developing the mobile application using React Native, I also worked on the UI/UX design for the application to increase the user engagement and the conversion rate.",
      Colors: ["#27C7BC", "#F6F5F3", "#000000"],
      Fonts: [
        {
          title: "Poppins",
          samples: [
            "a b c d e f g h i j k l",
            "m n o p q r s t u v w x",
            "y z 1 2 3 4 5 6 7 8 9 0",
          ],
        },
      ],
      designDescription: [
        "Inspired by a modern, design-forward aesthetic",
        "Readable fonts for easy navigation",
        "A dynamic and engaging interface design",
        "Animated elements for a lively user experience",
      ],
    },
  ];
  return (
    <div className="exp container container__large" id="experiences">
      <motion.h1
        initial="hidden"
        whileInView="visible"
        viewport={{
          once: true,
        }}
        transition={{
          y: { type: "spring", damping: 15 },
          default: { duration: 1 },
        }}
        variants={{
          visible: { y: 0, opacity: 1 },
          hidden: { y: 100, opacity: 0 },
        }}
        className="exp-title"
      >
        Experiences
      </motion.h1>
      <div className="exp-cards">
        {EXP_DATA.map((exp) => (
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{
              once: true,
            }}
            transition={{ duration: 0.8 }}
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: 100 },
            }}
            className="exp-card-container"
          >
            <ExpCard
              title={exp.title}
              tools={exp.tools}
              description={exp.description}
              image={exp.image}
              video={exp.video}
              color={exp.color}
              disabled={expandedDay !== exp && expandedDay !== undefined}
              //@ts-ignore
              onExpand={() => setCollapsedDay(day)}
              //@ts-ignore
              onCollapse={() => setCollapsedDay()}
              key={exp.title}
              site={exp.site}
              mobile={exp.mobile}
              alt={exp.alt}
              proImage={exp.proImage}
              cover={exp.cover}
              date={exp.date}
              tasks={exp.tasks}
              preparationTitle={exp.preprationTitle}
              preparationDescription={exp.preprationDescription}
              Colors={exp.Colors}
              alternativeColors={exp.AlternativeColors}
              preImage={exp.preImage}
              Fonts={exp.Fonts}
              designDescription={exp.designDescription}
            />
          </motion.div>
        ))}
      </div>
    </div>
  );
}
