import React, { useRef, useCallback, useState } from "react";
import "./contactBar.scss";
import { motion, AnimatePresence } from "framer-motion";
import emailjs from "@emailjs/browser";
import { IoArrowForward, IoCallOutline, IoCloseSharp } from "react-icons/io5";
//@ts-ignore
import pdf from "../images/Mahdi_Taziki-Resume.pdf";
// @ts-ignore
export default function ContactBar({ open, cycleOpen }) {
  const form = useRef();
  const [emailError, setEmailError] = useState(false);

  //@ts-ignore
  const resetForm = (e) => {
    e.preventDefault();
    setEmailError(false);
    var frm = document.getElementsByClassName("form")[0];
    //@ts-ignore
    frm.reset();
    return false;
  };
  // @ts-ignore
  const sendEmail = (e: React.FormEvent) => {
    e.preventDefault();

    // Email validation regex pattern
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (form.current) {
      //@ts-ignore
      const emailInput = form.current["user_email"].value;

      if (!emailPattern.test(emailInput)) {
        setEmailError(true);
        return;
      }
    }

    emailjs
      .sendForm(
        "service_j7bjtzf",
        "template_ah0y9da",
        //@ts-ignore
        form.current,
        "user_f2vgWPFdAhqyridaFXuvs"
      )
      .then(
        (result) => {
          handleSubmit();
          resetForm(e);
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
          console.log(form.current);
        }
      );
  };

  const $body = document.querySelector("body");
  const lockScroll = () => {
    if ($body) {
      $body.style.overflow = "hidden";
      $body.style.width = "100%";
    }
  };
  const unlockScroll = () => {
    if ($body) {
      $body.style.removeProperty("overflow");
      $body.style.removeProperty("width");
    }
  };
  const handleClick = useCallback(() => {
    if (open) {
      unlockScroll();
      emailError && setEmailError(false);
    } else {
      lockScroll();
    }
    cycleOpen();
  }, [open, cycleOpen]);

  // @ts-ignore
  const handleSubmit = () => {
    // @ts-ignore
    var target = document.getElementsByClassName("submit-button")[0];
    if (target.classList.contains("done")) {
    } else {
      target.classList.add("processing");
      setTimeout(() => {
        target.classList.remove("processing");
        target.classList.add("done");
      }, 2200);
      setTimeout(() => {
        target.classList.remove("done");
      }, 3500);
    }
  };

  return (
    <>
      <AnimatePresence>
        {open && (
          <>
            <motion.div
              className="overlay-contact"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              onClick={handleClick}
            />
            <motion.div
              className="contact-bar"
              initial="hidden"
              animate="visible"
              transition={{
                duration: 0.3,
              }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 },
              }}
              exit={{
                opacity: 0,
                transition: { delay: 0.4 },
              }}
              // onClick={cycleOpen}
            >
              <motion.div
                className="about-me"
                initial="hidden"
                animate="visible"
                transition={{
                  default: { duration: 0.5 },
                }}
                variants={{
                  visible: { y: 0 },
                  hidden: { y: -1000 },
                }}
                exit="hidden"
              >
                <motion.h1
                  initial="hidden"
                  animate="visible"
                  transition={{
                    delay: 0.45,
                    duration: 0.5,
                    type: "spring",
                  }}
                  variants={{
                    visible: { x: 0, opacity: 1 },
                    hidden: { x: -1000, opacity: 0 },
                  }}
                  exit={{
                    x: -1000,
                    opacity: 0,
                    transition: { delay: 0.5 },
                  }}
                  className="about-me-title"
                >
                  About me.
                </motion.h1>
                <motion.div
                  className="about-me-description-container"
                  initial="hidden"
                  animate="visible"
                  transition={{
                    delay: 0.5,
                    duration: 0.5,
                    type: "spring",
                  }}
                  variants={{
                    visible: { x: 0, opacity: 1 },
                    hidden: { x: -1000, opacity: 0 },
                  }}
                  exit={{
                    x: -1000,
                    opacity: 0,
                    transition: { delay: 0.5 },
                  }}
                >
                  <motion.div
                    className="vertical-line"
                    initial={{ height: 0 }}
                    animate={{ height: "100%" }}
                    transition={{
                      delay: 0.7,
                      duration: 0.7,
                      ease: "easeInOut",
                    }}
                  />
                  {/*Description*/}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                      paddingBottom: "1rem",
                      overflow: "hidden", // Added to prevent initial content peek
                    }}
                  >
                    <motion.p
                      className="about-me-description-text"
                      initial={{
                        opacity: 0,
                        y: 50, // Start slightly below its original position
                      }}
                      animate={{
                        opacity: 1,
                        y: 0, // Move to its original position
                      }}
                      transition={{
                        delay: 0.9, // Slightly after vertical line starts
                        duration: 0.5,
                        type: "spring",
                        stiffness: 100,
                      }}
                    >
                      Full-stack developer with a passion for creating creative,
                      responsive websites and applications. Aside my study, I'd
                      like to create and contribute to open source projects.
                    </motion.p>
                    <motion.h3
                      className="about-me-description-title"
                      initial={{
                        opacity: 0,
                        y: 50,
                      }}
                      animate={{
                        opacity: 1,
                        y: 0,
                      }}
                      transition={{
                        delay: 1.0,
                        duration: 0.5,
                        type: "spring",
                        stiffness: 100,
                      }}
                    >
                      360º project vision
                    </motion.h3>
                    <motion.p
                      className="about-me-description-text"
                      initial={{
                        opacity: 0,
                        y: 50,
                      }}
                      animate={{
                        opacity: 1,
                        y: 0,
                      }}
                      transition={{
                        delay: 1.2,
                        duration: 0.5,
                        type: "spring",
                        stiffness: 100,
                      }}
                    >
                      I have worked in the main departments involved in the
                      creation of digital products:
                      <motion.ul
                        initial={{
                          opacity: 0,
                          y: 50,
                        }}
                        animate={{
                          opacity: 1,
                          y: 0,
                        }}
                        transition={{
                          delay: 1.3,
                          duration: 0.5,
                          type: "spring",
                          stiffness: 100,
                        }}
                      >
                        <li>Front-end development</li>
                        <li>UI/UX design</li>
                        <li>Project management</li>
                        <li>Software architecture</li>
                      </motion.ul>
                    </motion.p>
                    <motion.a
                      href={pdf}
                      className="resume-me effect-shine"
                      target="_blank"
                      rel="noopener noreferrer"
                      initial={{
                        opacity: 0,
                        y: 50,
                      }}
                      animate={{
                        opacity: 1,
                        y: 0,
                      }}
                      transition={{
                        delay: 1.4,
                        duration: 0.5,
                        type: "spring",
                        stiffness: 100,
                      }}
                    >
                      <span className="resume-me-text">My resume</span>
                      <span className="resume-me-icon">
                        <IoArrowForward />
                      </span>
                    </motion.a>
                  </div>
                </motion.div>
              </motion.div>
              <motion.div
                className="contact-me"
                initial="hidden"
                animate="visible"
                transition={{
                  default: { duration: 0.5 },
                }}
                variants={{
                  visible: { y: 0 },
                  hidden: { y: 1000 },
                }}
                exit="hidden"
              >
                {/*@ts-ignore*/}
                <form ref={form} onSubmit={sendEmail} className="form">
                  <div className="form-content">
                    <motion.h1
                      className="contact-me-title"
                      initial={{
                        opacity: 0,
                        x: -1000,
                      }}
                      animate={{
                        opacity: 1,
                        x: 0,
                      }}
                      transition={{
                        delay: 0.7,
                        duration: 0.5,
                        type: "spring",
                      }}
                    >
                      Let's Talk.
                    </motion.h1>
                    <motion.p
                      //@ts-ignore
                      type="Name *"
                      initial={{
                        opacity: 0,
                        y: 50,
                      }}
                      animate={{
                        opacity: 1,
                        y: 0,
                      }}
                      transition={{
                        delay: 0.9,
                        duration: 0.5,
                        type: "spring",
                        stiffness: 100,
                      }}
                    >
                      <input name="user_name" required />
                    </motion.p>
                    <motion.p
                      //@ts-ignore
                      type="E-mail *"
                      initial={{
                        opacity: 0,
                        y: 50,
                      }}
                      animate={{
                        opacity: 1,
                        y: 0,
                      }}
                      transition={{
                        delay: 1.1,
                        duration: 0.5,
                        type: "spring",
                        stiffness: 100,
                      }}
                    >
                      <input name="user_email" required />
                      {emailError && (
                        <motion.span
                          className="error-message"
                          initial={{
                            opacity: 0,
                            scale: 0.8,
                          }}
                          animate={{
                            opacity: 1,
                            scale: 1,
                          }}
                          transition={{
                            duration: 0.3,
                            type: "spring",
                          }}
                        >
                          Please enter a valid email address
                        </motion.span>
                      )}
                    </motion.p>
                    <motion.p
                      //@ts-ignore
                      type="Message *"
                      initial={{
                        opacity: 0,
                        y: 50,
                      }}
                      animate={{
                        opacity: 1,
                        y: 0,
                      }}
                      transition={{
                        delay: 1.3,
                        duration: 0.5,
                        type: "spring",
                        stiffness: 100,
                      }}
                    >
                      <textarea name="message" required />
                    </motion.p>
                    <motion.button
                      className="submit-button spin"
                      id="spin"
                      type="submit"
                      value="Send"
                      initial={{
                        opacity: 0,
                        y: 0,
                      }}
                      animate={{
                        opacity: 1,
                        y: 0,
                      }}
                      transition={{
                        delay: 1.5,
                        duration: 0.5,
                        type: "spring",
                        stiffness: 100,
                      }}
                    >
                      <span className="submit-span">Submit</span>
                      <span className="submit-span">
                        <svg viewBox="0 0 24 24">
                          <path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                        </svg>
                      </span>
                    </motion.button>
                  </div>
                  <motion.div
                    className="bottom-contact"
                    onClick={() => window.open("tel:+17788744934")}
                    initial={{
                      opacity: 0,
                      y: 50,
                    }}
                    animate={{
                      opacity: 1,
                      y: 0,
                    }}
                    transition={{
                      delay: 1.7,
                      duration: 0.5,
                      type: "spring",
                      stiffness: 100,
                    }}
                  >
                    <IoCallOutline className="contact-icon" />
                    +1 (778) 874-4934
                  </motion.div>
                </form>
                <div className="contact-close" onClick={handleClick}>
                  <IoCloseSharp className="contact-icon" />
                </div>
              </motion.div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </>
  );
}
