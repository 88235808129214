import React from "react";
import "./footer.scss";
import {
  RiGithubFill,
  RiLinkedinBoxFill,
  RiTelegramFill,
} from "react-icons/ri";

export default function Footer() {
  const date = new Date();
  const year = date.getFullYear();
  return (
    <div className="footer container">
      <footer>
        <div className="copyRight">
          <p>&copy; {year} - Mahdi Taziki. No rights reserved.</p>
        </div>
        <div className="socialMedia">
          <button
            className="slide"
            onClick={() => {
              window.location.href = "mailto:mahdi.taziki99@gmail.com";
            }}
          >
            Email Me!
          </button>
          <div className="social-links-footer">
            <a href="https://github.com/Mhd-tz" className="social-link-footer"
              target="_blank"                 // Add this line to open in a new tab
              rel="noopener noreferrer"       // Add this line for security
            >
              <RiGithubFill className="social-icon-footer" />
            </a>
            <a
              href="https://www.linkedin.com/in/mahdi-taziki"
              className="social-link-footer"
              target="_blank"                 // Add this line to open in a new tab
              rel="noopener noreferrer"       // Add this line for security
            >
              <RiLinkedinBoxFill className="social-icon-footer" />
            </a>
            <a href="https://t.me/mhd_tz" className="social-link-footer"
              target="_blank"                 // Add this line to open in a new tab
              rel="noopener noreferrer"       // Add this line for security
            >
              <RiTelegramFill className="social-icon-footer" />
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
}
