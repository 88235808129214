import { useState, useRef, useEffect } from "react";
import { gsap } from "gsap";
import "./App.scss";
//@ts-ignore
import $ from "jquery";
import { motion, useCycle } from "framer-motion";
import Navbar from "./components/Navbar/Navbar";
import Intro from "./components/Intro/Intro";
import Projects from "./components/Projects/Projects";
import SideBar from "./components/SideBar";
import Footer from "./components/Footer/Footer";
import Exp from "./components/Experiences/Exp";
import { IoChevronUpSharp } from "react-icons/io5";
//@ts-ignore
import smoothscroll from "smoothscroll-polyfill";
import ContactBar from "./components/ContactBar";

smoothscroll.polyfill();
//@ts-ignores
window.__forceSmoothScrollPolyfill__ = true;

function Loader() {
  return (
    <div id="loader">
      <div className="white" />
      <div className="logo-wrap">
        <div className="logo-text">
          M<span className="dot">.</span>
        </div>
      </div>
    </div>
  );
}

function App() {
  const [loading, setLoading] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const [open, cycleOpen] = useCycle(false, true);
  const [contactOpen, cycleContactOpen] = useCycle(false, true);
  const el = useRef();
  const q = gsap.utils.selector(el);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    $(window).on("scroll", function () {
      if (window.scrollY > 70) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    });
  }, []);
  useEffect(() => {
    let scrollPercentage = () => {
      let scrollProgress = Array.from(
        document.getElementsByClassName(
          "progress"
        ) as HTMLCollectionOf<HTMLElement>
      );
      let pos = document.documentElement.scrollTop;
      let calcHeight =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      let scrollValue = Math.round((pos * 100) / calcHeight);
      if (scrollProgress) {
        scrollProgress.forEach((item) => {
          item.style.background = `conic-gradient(#36184f ${scrollValue}%, #695681 ${scrollValue}%)`;
        });
      }
    };
    window.onscroll = scrollPercentage;
  }, []);

  useEffect(() => {
    gsap.to(q(".white"), {
      y: 1500,
      duration: 1.8,
      delay: 1.1,
      // repeat: -1,
      // repeatDelay: 1,
      // yoyo: true,
    });
    gsap.to(q(".logo-text"), {
      y: 100,
      duration: 0.5,
      delay: 1.6,
    });

    setTimeout(() => {
      setLoading(false);
    }, 2350);
  }, []);

  return (
    //@ts-ignore
    <div ref={el}>
      {loading ? (
        <Loader />
      ) : (
        //@ts-ignore
        <div className="App">
          <motion.div
            initial={{ y: 100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{
              y: { type: "spring", damping: 15 },
              default: { duration: 1 },
            }}
          >
            <div
              className={isVisible ? "progress" : "progress hidden"}
              onClick={scrollToTop}
            >
              <span id="progress-value">
                <IoChevronUpSharp className="scroll-icon" />
              </span>
            </div>
            <Navbar
              open={open}
              cycleOpen={cycleOpen}
              contactOpen={contactOpen}
              cycleContactOpen={cycleContactOpen}
            />
            <SideBar
              open={open}
              cycleOpen={cycleOpen}
              contactOpen={contactOpen}
              cycleContactOpen={cycleContactOpen}
            />
            <ContactBar open={contactOpen} cycleOpen={cycleContactOpen} />
            <Intro />
            <div className="sections">
              <Exp />
              <Projects />
              <Footer />
            </div>
          </motion.div>
        </div>
      )}
    </div>
  );
}

export default App;
