import { useCallback, useEffect, useState } from "react";
import "./navbar.scss";
// @ts-ignore
import $ from "jquery";
import Theme from "../ThemeToggler/Theme";

export default function Navbar({
  // @ts-ignore
  open,
  // @ts-ignore
  cycleOpen,
  // @ts-ignore
  contactOpen,
  // @ts-ignore
  cycleContactOpen,
}) {
  const [isVisible, setIsVisible] = useState(true);
  useEffect(() => {
    //@ts-ignore
    $(window).on("scroll", function () {
      if (window.scrollY > 70 && window.innerWidth > 775) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    });
  }, []);
  // @ts-ignore
  const onClickExp = (e) => {
    e && e.preventDefault(); // to avoid the link from redirecting
    const elementToView = document.getElementById("experiences");
    if (elementToView) {
      elementToView.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  // @ts-ignore
  const onClickProjects = (e) => {
    e && e.preventDefault(); // to avoid the link from redirecting
    const elementToView = document.getElementById("projects");
    if (elementToView) {
      elementToView.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  // @ts-ignore
  const onClickContact = (e) => {
    e && e.preventDefault(); // to avoid the link from redirecting
    cycleContactOpen();
    //lock the scroll
    lockScroll();
  };

  const $body = document.querySelector("body");
  const lockScroll = () => {
    if ($body) {
      $body.style.overflow = "hidden";
      $body.style.width = "100%";
    }
  };
  const unlockScroll = () => {
    if ($body) {
      $body.style.removeProperty("overflow");
      $body.style.removeProperty("width");
    }
  };
  const handleClick = useCallback(() => {
    if (open) {
      unlockScroll();
    } else {
      lockScroll();
    }
    cycleOpen();
  }, [open, cycleOpen]);

  return (
    <nav className={isVisible ? "nav" : "nav dismissed"}>
      <div className="container navbar">
        <div className="website_name">
          M<span className="dot">.</span>
        </div>
        <ul className="navbar_list">
          <li className="navbar_link">
            <a onClick={onClickExp} href="#experiences" className="link">
              Experiences
            </a>
          </li>
          <li className="navbar_link">
            <a onClick={onClickProjects} href="#projects" className="link">
              projects
            </a>
          </li>
          <li className="navbar_link">
            <a onClick={onClickContact} className="link">
              About Me
            </a>
          </li>
          <li className="navbar_link">
            <Theme />
          </li>
        </ul>
        <div className="hamburger">
          <Theme />
        </div>
        {/*@ts-ignore*/}
        <svg
          className={open ? "ham ham6 active" : "ham ham6"}
          viewBox="0 0 100 100"
          width="80"
          onClick={handleClick}
        >
          <path
            className="line top"
            d="m 30,33 h 40 c 13.100415,0 14.380204,31.80258 6.899646,33.421777 -24.612039,5.327373 9.016154,-52.337577 -12.75751,-30.563913 l -28.284272,28.284272"
          />
          <path
            className="line middle"
            d="m 70,50 c 0,0 -32.213436,0 -40,0 -7.786564,0 -6.428571,-4.640244 -6.428571,-8.571429 0,-5.895471 6.073743,-11.783399 12.286435,-5.570707 6.212692,6.212692 28.284272,28.284272 28.284272,28.284272"
          />
          <path
            className="line bottom"
            d="m 69.575405,67.073826 h -40 c -13.100415,0 -14.380204,-31.80258 -6.899646,-33.421777 24.612039,-5.327373 -9.016154,52.337577 12.75751,30.563913 l 28.284272,-28.284272"
          />
        </svg>
      </div>
    </nav>
  );
}
