import React, { useCallback } from "react";
import { AnimatePresence, motion } from "framer-motion";
import {
  RiGithubFill,
  RiLinkedinBoxFill,
  RiTelegramFill,
} from "react-icons/ri";
const links = [
  { name: "Experiences", to: "#experiences", id: 1 },
  { name: "Projects", to: "#projects", id: 2 },
  { name: "About Me", to: "#", id: 3 },
];

const socials = [
  { icon: <RiGithubFill />, to: "https://github.com/Mhd-tz", id: 1 },
  {
    icon: <RiLinkedinBoxFill />,
    to: "https://www.linkedin.com/in/mahdi-taziki",
    id: 2,
  },
  { icon: <RiTelegramFill />, to: "https://t.me/mhd_tz", id: 3 },
];

const itemVariants = {
  closed: {
    opacity: 0,
  },
  open: { opacity: 1 },
};

const sideVariants = {
  closed: {
    transition: {
      staggerChildren: 0.05,
      staggerDirection: -1,
    },
  },
  open: {
    transition: {
      staggerChildren: 0.05,
      staggerDirection: 1,
    },
  },
};

export default function SideBar({
  // @ts-ignore
  open,
  // @ts-ignore
  cycleOpen,
  // @ts-ignore
  contactOpen,
  // @ts-ignore
  cycleContactOpen,
}) {
  // @ts-ignore
  const onClickExp = (e) => {
    e && e.preventDefault(); // to avoid the link from redirecting
    const elementToView = document.getElementById("experiences");
    if (elementToView) {
      elementToView.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  // @ts-ignore
  const onClickProjects = (e) => {
    e && e.preventDefault(); // to avoid the link from redirecting
    const elementToView = document.getElementById("projects");
    if (elementToView) {
      elementToView.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  // @ts-ignore
  const onClickContact = (e) => {
    e && e.preventDefault(); // to avoid the link from redirecting
    cycleContactOpen();
  };

  const $body = document.querySelector("body");
  const lockScroll = () => {
    if ($body) {
      $body.style.overflow = "hidden";
      $body.style.width = "100%";
    }
  };
  const unlockScroll = () => {
    if ($body) {
      $body.style.removeProperty("overflow");
      $body.style.removeProperty("width");
    }
  };
  const handleClick = useCallback(() => {
    if (open) {
      unlockScroll();
    } else {
      lockScroll();
    }
    cycleOpen();
  }, [open, cycleOpen]);

  return (
    <div>
      <AnimatePresence>
        {open && (
          <div className="nav-mobile">
            <motion.div
              className="nav-background"
              initial={{ width: 0 }}
              animate={{
                width: "100%",
              }}
              exit={{
                width: 0,
                transition: { delay: 0.35, duration: 0.15 },
              }}
            />
            <motion.aside
              initial={{ width: 0 }}
              animate={{
                width: "50%",
              }}
              exit={{
                width: 0,
                transition: { delay: 0.35, duration: 0.15 },
              }}
            >
              <motion.div
                className="nav-side"
                initial="closed"
                animate="open"
                exit="closed"
                variants={sideVariants}
              >
                {links.map(({ name, to, id }) => (
                  <motion.a
                    key={id}
                    href={to}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    variants={itemVariants}
                    onClick={(e) => {
                      handleClick();
                      if (name === "Experiences") {
                        onClickExp(e);
                      }
                      if (name === "Projects") {
                        onClickProjects(e);
                      } else if (name === "About Me") {
                        onClickContact(e);
                        lockScroll();
                      }
                    }}
                  >
                    {name}
                  </motion.a>
                ))}
              </motion.div>
              <motion.div
                className="side-socials"
                initial="closed"
                animate="open"
                exit="closed"
                variants={sideVariants}
              >
                {socials.map(({ icon, to, id }) => (
                  <motion.a
                    target="_blank"
                    rel="noopener noreferrer"
                    key={id}
                    href={to}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    variants={itemVariants}
                    onClick={() => handleClick()}
                  >
                    {icon}
                  </motion.a>
                ))}
              </motion.div>
            </motion.aside>
          </div>
        )}
      </AnimatePresence>
    </div>
  );
}
