import { motion, AnimateSharedLayout } from "framer-motion";
import { IoCloseSharp } from "react-icons/io5";
import React, { useState, useCallback } from "react";
import "./expCard.scss";
import { IoIosExpand } from "react-icons/io";
import ExpandingButton from "../ui/expandingButton";

interface Font {
  title: string;
  samples: string[];
}

interface ContentProps {
  title?: string;
  tools?: React.ReactNode;
  image: string;
  video?: string;
  description?: string;
  color?: string;
  disabled?: boolean;
  onCollapse?: () => void;
  site?: string;
  mobile?: boolean;
  alt?: string;
  proImage?: string;
  cover?: boolean;
  noContainer?: boolean;
  isExpanded?: boolean;
  tasks?: Array<string>;
  preparationTitle?: string;
  preparationDescription?: string;
  Colors?: Array<string>;
  preImage?: string;
  Fonts?: Font[];
  designDescription?: Array<string>;
  alternativeColors?: Array<string>;
}

function Content({
  title,
  tools,
  image,
  video,
  description,
  color,
  disabled,
  onCollapse,
  site,
  mobile,
  alt,
  proImage,
  cover,
  noContainer,
  isExpanded,
  tasks,
  preparationTitle,
  preparationDescription,
  Colors,
  preImage,
  Fonts,
  designDescription,
  alternativeColors,
}: // Fonts,
ContentProps) {
  const $body = document.querySelector("body");
  const unlockScroll = () => {
    if ($body) {
      $body.style.removeProperty("overflow");
      $body.style.removeProperty("width");
    }
  };
  const handleClick = useCallback(() => {
    unlockScroll();
    onCollapse?.(); // Add optional chaining to handle undefined onCollapse
  }, [disabled, onCollapse]);

  if (isExpanded) {
    return (
      <motion.div className="expCard-content">
        <div
          className="expCard-close"
          onClick={() => {
            handleClick();
          }}
        >
          <IoCloseSharp />
        </div>
        <motion.div className="expCard-main">
          <motion.div className="content-container">
            {/* <motion.div className="content-tools">
              <motion.div className="content-tools-item">{tools}</motion.div>
            </motion.div> */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <motion.p
                // href={site}
                // target="_blank"
                // rel="noopener noreferrer"
                className="content-title"
                style={{ color: color }}
              >
                {title}
              </motion.p>
              <ExpandingButton
                onClick={() => {
                  window.open(site, "_blank");
                }}
                // site={site}
                label="Visit"
                color={color}
                // title={title}
                // tools={tools}
              />
            </div>
            <p>
              {description?.split(". ").map((sentence) => (
                <>
                  {sentence}
                  <br style={{ marginBottom: "1.2rem" }} />
                </>
              ))}
            </p>
            {/* <ul>
              {tasks?.map((task) => (
                <li>{task}</li>
              ))}
            </ul> */}
            <div className="task-bubble-container">
              {tasks &&
                tasks.map((task) => <div className="task-bubble">{task}</div>)}
            </div>
          </motion.div>
          {image && (
            <motion.div className="right-side-image">
              <img
                className="expCard-side-image"
                src={image}
                alt={alt || "Right-side image"}
              />
            </motion.div>
          )}
        </motion.div>
        <motion.div className="analysis-preparation-container">
          <h2>Analysis & Preparation</h2>
          <h1>Branding</h1>
          <div className="analysis-container">
            <div
              style={{
                flex: 2,
              }}
            >
              <div className="preparation-title-container">
                <h3
                  style={{
                    borderBottom: `4px solid ${color}`,
                    width: "fit-content",
                    paddingBottom: "0.5rem",
                  }}
                >
                  {preparationTitle}
                </h3>
                {preparationDescription?.split(". ").map((sentence) => (
                  <p className="preparation-description">
                    {sentence}
                    <br style={{ marginBottom: "1rem" }} />
                  </p>
                ))}
              </div>
            </div>
            {preImage && (
              <div className="right-side-image">
                <img
                  className="expCard-side-image analysis-image"
                  src={preImage}
                  alt={alt || "Right-side image"}
                />
              </div>
            )}
          </div>

          <h1>Colors</h1>
          <div className="color-container">
            {Colors?.map((color, index) => (
              <div className="color-bubble-container">
                <div
                  key={index}
                  className="color-bubble"
                  style={{
                    backgroundColor: color,
                  }}
                />
                <p>{color}</p>
              </div>
            ))}
          </div>
          <h1>Fonts</h1>
          <div className="font-container">
            {Fonts?.map((font, index) => (
              <div key={index} className="font-bubble-container">
                <h3 style={{ fontFamily: font.title }}>{font.title}</h3>
                <div className="font-samples">
                  {font.samples.map((sample, sampleIndex) => (
                    <p key={sampleIndex} style={{ fontFamily: font.title }}>
                      {sample}
                    </p>
                  ))}
                </div>
              </div>
            ))}
          </div>

          <h2>UI & Components.</h2>
          <h1>Design</h1>
          {
            <div className="design-description-container">
              {designDescription?.map((sentence) => (
                <p>• {sentence}</p>
              ))}
            </div>
          }
          {proImage && (
            <div
              className="right-side-image"
              style={{
                marginTop: "2rem",
              }}
            >
              <img
                className="expCard-side-image analysis-image"
                src={proImage}
                alt={alt || "Right-side image"}
              />
            </div>
          )}
          {video && (
            <div className="video-container">
              {!video ? null : mobile ? (
                <img className="content-image mobile" src={video} alt="" />
              ) : (
                <img className="content-image" src={video} alt="" />
              )}
              {!proImage ? null : !cover ? (
                <img className="imagePro" src={proImage} alt="" />
              ) : (
                <img className="imagePro cover" src={proImage} alt="" />
              )}
            </div>
          )}
        </motion.div>
      </motion.div>
    );
  }

  return (
    <motion.div
      className="expCard-content"
      layoutId="title"
      style={{ opacity: disabled ? 0.2 : 1 }}
    >
      <div
        className="expCard-close"
        onClick={() => {
          handleClick();
        }}
      >
        <IoCloseSharp />
      </div>
      <motion.div
        className={`expCard-image-container ${
          noContainer ? "noContainer" : ""
        }`}
      >
        <img className="expCard-image" src={image} alt={alt} />
      </motion.div>
      <motion.div className="expCard-main">
        <motion.div className="content-container">
          <motion.div className="content-tools">
            <motion.div className="content-tools-item">{tools}</motion.div>
          </motion.div>
          <motion.a
            href={site}
            target="_blank"
            rel="noopener noreferrer"
            className="content-title"
            style={{ color: color }}
          >
            {title}
          </motion.a>
          <p>{description}</p>
        </motion.div>
        {/* Only render the right-side image when expanded */}
        {image && (
          <motion.div className="right-side-image">
            <img
              className="expCard-side-image"
              src={image}
              alt={alt || "Right-side image"}
            />
          </motion.div>
        )}
      </motion.div>
    </motion.div>
  );
}

function ExpandedCard({ children }: { children: React.ReactNode }) {
  return (
    <>
      <motion.div className="expCard expanded" layoutId="expandable-card">
        {children}
      </motion.div>
    </>
  );
}

interface CompactCardProps {
  children: React.ReactNode;
  onExpand: () => void;
  disabled: boolean;
}

function CompactCard({ children, onExpand, disabled }: CompactCardProps) {
  const $body = document.querySelector("body");
  const lockScroll = () => {
    if ($body) {
      $body.style.overflow = "hidden";
      $body.style.width = "100%";
    }
  };

  const handleClick = useCallback(() => {
    if (disabled) return;
    lockScroll();
    onExpand();
  }, [disabled, onExpand]);

  return (
    <motion.div
      className="expCard compact"
      layoutId="expandable-card"
      onClick={() => {
        handleClick();
      }}
      whileHover={{
        scale: 1.02,
        cursor: "pointer",
        transition: { duration: 0.35 },
      }}
    >
      <div className="compact-overlay" />
      {children}
    </motion.div>
  );
}

interface ExpCardProps extends ContentProps {
  onCollapse: () => void;
  onExpand: () => void;
  disabled: boolean;
  date: string;
}

export default function ExpCard({
  title,
  tools,
  image,
  description,
  video,
  color,
  onCollapse,
  onExpand,
  disabled,
  site,
  mobile,
  alt,
  proImage,
  cover,
  date,
  tasks,
  preparationTitle,
  preparationDescription,
  Colors,
  preImage,
  Fonts,
  designDescription,
  alternativeColors,
}: // Fonts,
ExpCardProps) {
  const [isExpanded, setIsExpanded] = useState(false);

  const collapseDate = () => {
    setIsExpanded(false);
    onCollapse();
  };

  const expandDate = () => {
    setIsExpanded(true);
    onExpand();
  };
  return (
    <div className="expCard-container">
      {/* @ts-ignore */}
      <AnimateSharedLayout>
        {isExpanded ? (
          <ExpandedCard>
            <Content
              onCollapse={collapseDate}
              disabled={disabled}
              title={title}
              tools={tools}
              image={image}
              video={video}
              description={description}
              color={color}
              site={site}
              mobile={mobile}
              alt={alt}
              proImage={proImage}
              cover={cover}
              isExpanded={isExpanded}
              tasks={tasks}
              preparationTitle={preparationTitle}
              preparationDescription={preparationDescription}
              Colors={Colors}
              preImage={preImage}
              Fonts={Fonts}
              designDescription={designDescription}
              alternativeColors={alternativeColors}
            />
          </ExpandedCard>
        ) : (
          <CompactCard onExpand={expandDate} disabled={disabled}>
            <div className="expCard-image-container">
              <div className="expCard-title-container">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <p className="expCard-title">{title}</p>
                  <p className="expCard-date">{date}</p>
                </div>
                <IoIosExpand className="expand-icon" />
              </div>
              <Content
                image={image}
                disabled={disabled}
                noContainer
                isExpanded={isExpanded}
              />
            </div>
          </CompactCard>
        )}
      </AnimateSharedLayout>
    </div>
  );
}
